import React, { useState } from 'react';

// core components
import Navbar from "components/Navbars/Navbar.js";
import SubHeader from "components/Headers/SubHeaderBanner";
import DarkFooter from "components/Footers/DarkFooter.js";
import PanelCountdown from "components/PanelCountdown";


function Visa() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        event.preventDefault()
    };


    return (
        <>
            <Navbar />
            <SubHeader title="VISA" image={require("assets/img/visavietnambanner.png")} />
            <div className="wrapper position-relative">
                <div className="container main my-5">
                    <section className="section gb nopadtop">
                        <div className="container">
                            <div className="boxed boxedp4">
                                    <div className="section-title">
                                        <h2 className="text-center font-weight-bold">VISA REQUIREMENTS</h2>
                                    </div>
                                <div className="bg-white shadow px-2 pt-5 pb-3 my-3">
                                    <div className="col-md-12 mb-5">
                                    <h4 className='font-weight-bold'> New policies from 15th August 2023</h4>
                                         <p className="text-justify">
                                            1. Vietnam grants e-visas for citizens of all countries and territories with 90-day stay duration and valid for multiple entry. <br/>
                                            2. Citizens of 13 countries enjoying unilateral visa exemption can stay in Vietnam up to 45 days.
                                         </p>

                                        <h5 className='font-weight-bold'>VIETNAM VISA EXEMPTION</h5>
                                        <p className='text-justify'>
                                        Effective 15th August 2023, Vietnam extends unilateral visa exemption to 25 countries from 15 days to 45 days, regardless of passport type 
                                        and entry purpose. The 25 countries include:  <span className='font-weight-bold'>Brunei, Myanmar, Belarus, Denmark, Finland, France, Germany, Italy, 
                                        Japan, Norway, Russia, South Korea, Spain, Sweden, The UK, Philippines, Cambodia, Indonesia, Kyrgyzstan, 
                                        Laos, Malaysia, Singapore, Thailand, Chile, Panama. </span>
                                        </p>

                                        <p>More information on visa requirements and application for online e-visa can be found on 
                                            <a href='https://vietnamvisa.govt.vn/vietnam-visa/how-to-apply/'> https://vietnamvisa.govt.vn/vietnam-visa/how-to-apply/</a> </p>
                                        
                                        <p className='d-none d-md-block'>Should you require an invitation letter from FIBS Logistics for 
                                            your visa application, kindly get in touch with us at 
                                            <a rel="noopener" href="mailto:customerservice@fibsglobalconference.com"> customerservice@fibsglobalconference.com</a></p>

                                        <p className='d-md-none'>Should you require an invitation letter from FIBS Logistics for 
                                            your visa application, 
                                            <a rel="noopener" href="mailto:customerservice@fibsglobalconference.com"> kindly get in touch with us.</a></p>

                                         {/* <div>
                                            <img className='flex' height={500} src="https://vietnam.travel/sites/default/files/inline-images/1345.jpg" alt="visa img" />
                                         </div> */}

                                       {/* <p className="text-justify">
                                            <a target="_blank" href='https://u.ae/en/information-and-services/visa-and-emirates-id/do-you-need-an-entry-permit-or-a-visa-to-enter-the-uae'>https://u.ae/en/information-and-services/visa-and-emirates-id/do-you-need-an-entry-permit-or-a-visa-to-enter-the-uae</a>
                                        </p> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
                <PanelCountdown date="02/18/2025" />
                {/* <DarkFooter /> */}
            </div>
        </>
    );
}

export default Visa;
