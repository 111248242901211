/*

=========================================================
* Now UI Kit React - v1.5.1
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2022 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.scss?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LoginPage from "views/examples/LoginPage.js";
import AboutVietnam from "views/examples/AboutVietnam.js";
import Agenda from "views/agenda";
import Visa from "views/visa";
import Registration from "views/registration";
import ContactUs from "views/contact";
import MeetingCost from "views/meetingCost";
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Sponsor from "views/sponsor";
import Hotel from "views/hotel";
import List from "views/list";
import Lodge from "views/list/Lodge";
import Login from "views/list/login";
import Gallery from "views/gallery";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

  <BrowserRouter basename='/event/'>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route
          path="/contact-us"
          render={(props) => <ContactUs {...props} />}
        />
        <Route
          path="/agenda"
          render={(props) => <Agenda {...props} />}
        />
        <Route
          path="/registration"
          render={(props) => <Registration {...props} />}
        />
        {/* <Route
          path="/nucleo-icons"
          render={(props) => <NucleoIcons {...props} />}
        /> */}

        <Route
          path="/sponsorship"
          render={(props) => <Sponsor {...props} />}
        />

        <Route
          path="/login-page"
          render={(props) => <LoginPage {...props} />}
        />
        <Route
          path="/about-vietnam"
          render={(props) => <AboutVietnam {...props} />}
        />
        <Route
          path="/visa"
          render={(props) => <Visa {...props} />}
        />
        <Route
          path="/hotel"
          render={(props) => <Hotel {...props} />}
        />
        <Route 
          path="/meeting-cost"
          render={(props) => <MeetingCost {...props}/>}
        />
        <Route
          path="/post-event-2023"
          render={(props) => <Gallery {...props} />}
        />
        <Route
          path="/v2/fibs/list"
          render={(props) => <List {...props} />}
        />
        <Route
          path="/v2/fibs/hotel-list"
          render={(props) => <Lodge {...props} />}
        />
        <Route
          path="/v2/fibs/login"
          render={(props) => <Login {...props} />}
        />
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>
);
