import React, { useState, useEffect } from 'react';
import {
    Tabs, Tab, Typography, Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    TextField,
    InputProps,
    Button
} from '@mui/material';
import moment from 'moment-timezone'

import app from '../../utils/firebase';
import { ref, set } from "firebase/database"

import { useForm } from "react-hook-form";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import Swal from "sweetalert2";

// core components
import Navbar from "components/Navbars/Navbar.js";
import SubHeader from "components/Headers/SubHeaderBanner";
import DarkFooter from "components/Footers/DarkFooter.js";
import PanelCountdown from "components/PanelCountdown";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import './style.scss';
import DtPicker from 'react-calendar-datetime-picker'
import 'react-calendar-datetime-picker/dist/index.css'

// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers/DateRangePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from "axios";
// const MySwal = withReactContent(Swal)

// MySwal.fire({
//     title: <p>Hello World</p>,
//     didOpen: () => {
//         // `MySwal` is a subclass of `Swal` with all the same instance & static methods
//         MySwal.showLoading()
//     },
// }).then(() => {
//     return MySwal.fire(<p>Shorthand works too</p>)
// })

const style = {
    noBorder: {
        border: "none",
    },
    root: {
        borderColor: 'orange'
    }
}

function Hotel() {

    const db = app();

    const [selectedDate, handleDateChange] = useState(new Date());


    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    const validation = Yup.object().shape({
        hotel_name: Yup.string().required("This is a required field"),
        room_type: Yup.string().required("This is a required field"),
        name: Yup.string().required("This is a required field"),
        contact_number: Yup.string().required("This is a required field"),
        email: Yup.string().email().required("This is a required field"),
        airport_transfer: Yup.string().required("This is a required field")
    })

    const optionsForm = { resolver: yupResolver(validation) }
    const { register, handleSubmit, reset, formState: { errors } } = useForm(optionsForm)

    const [dateCalendar, setDateCalendar] = useState(null)
    // const [value, setValue] = React.useState(0);

    let date = moment().tz('Asia/Singapore').format('ddd, MMM D YYYY');

    const [checkInvalue, setCheckInvalue] = useState(moment("2/18/2025"));
    const [checkOutvalue, setCheckOutvalue] = useState(moment("2/22/2025"));

    const options = [
        {
            label: "Danang Marriott Resort & Spa",
            value: "4,200,000 VND",
        },
        // {
        //     label: "Le Meridien Mina Seyahi",
        //     value: "AED 3‘750",
        // }
    ]

    const [hotelDetails, setHotelDetails] = useState({})

    const getHotelCalendar = (data) => {
        console.log(data)
        setHotelDetails(data)
    }

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    //     event.preventDefault()
    // };

    const handleChange = (e) => {
        setHotelDetails({ value: e.target.value });
        e.preventDefault()
    }

    const showAlert = () => {
        Swal.fire({
            title: "Success",
            text: "Alert successful",
            icon: "success",
            confirmButtonText: "OK",
        });

    }
    const onSubmit = data => {

        const uid = new Date().getTime().toString();
        let date = moment().tz('Asia/Singapore').format('LLLL');

        set(ref(db, 'hotels/' + uid), {
            ...data,
            id: uid,
            check_in: dayjs(checkInvalue).format('ddd, MMM D, YYYY'),
            check_out: dayjs(checkOutvalue).format('ddd, MMM D, YYYY'),
            created_at: date
        })

        Swal.fire({
            title: "Reservation successful",
            text: "Please expect an email from us.",
            icon: "success",
            confirmButtonText: "OK",
        });

        const autoMessageRegistered = `<div> <p>Good day!</p><p>
        Kindly check below details for hotel reservation of ${data.name} </p> 
        <table style="border:1px solid black;">
            <tr>
                <td style="border:1px solid black;"><strong>Id:</strong> </td>
                <td style="border:1px solid black;">${uid}</td>
            </tr>
            <tr>
                <td style="border:1px solid black;"><strong>Name:</strong> </td>
                <td style="border:1px solid black;">${data.name} </td>
            </tr>
            <tr>
                <td style="border:1px solid black;"><strong>Email:</strong> </td>
                <td style="border:1px solid black;">${data.email}</td>
            </tr>
            <tr>
                <td style="border:1px solid black;"><strong>Check-in:</strong> </td>
                <td style="border:1px solid black;">${dayjs(checkInvalue).format('ddd, MMM D, YYYY')}</td>
            </tr>
            <tr>
                <td style="border:1px solid black;"><strong>Check-out:</strong> </td>
                <td style="border:1px solid black;">${dayjs(checkOutvalue).format('ddd, MMM D, YYYY')}</td>
            </tr>
            <tr>
                <td style="border:1px solid black;"><strong>Flight Details:</strong> </td>
                <td style="border:1px solid black;">${data.flight_details}</td>
            </tr>
            <tr>
                <td style="border:1px solid black;"><strong>Hotel Name:</strong> </td>
                <td style="border:1px solid black;">${data.hotel_name}</td>
            </tr>
            <tr>
                <td style="border:1px solid black;"><strong>Room Type:</strong> </td>
                <td style="border:1px solid black;">${data.room_type}</td>
            </tr>    
            <tr>
                <td style="border:1px solid black;"><strong>Hotel Membership:</strong> </td>
                <td style="border:1px solid black;">${data.hotel_membership}</td>
            </tr>    
            <tr>
                <td style="border:1px solid black;"><strong>Contact Number:</strong> </td>
                <td style="border:1px solid black;">${data.contact_number}</td>
            </tr>     
            <tr>
                <td style="border:1px solid black;"><strong>Airport Transfer:</strong> </td>
                <td style="border:1px solid black;">${data.airport_transfer ? 'yes' : 'no'}</td>
            </tr>    
            <tr>
                <td style="border:1px solid black;"><strong>Request:</strong> </td>
                <td style="border:1px solid black;">${data.request}</td>
            </tr>                                      
        </table>
        <p>Thank you!</p> 
        <p>FIBS Admin</p></div>`
     
    //    const newRegistered = { 
    //      name: data.name,
    //      sendto: 'customerservice@fibsglobalconference.com',
    //     //  sendto: 'flytechph@gmail.com',
    //      subject: `Fibs Hotel Reservation - ${data.name}`,
    //      message: autoMessageRegistered 
    //    };
     
    //    axios.post(`https://api.fibsglobalconference.com/v2`, null, { 
    //      params: newRegistered
    //      })
    //    .then((response) => {
    //     //  console.log(response.data);
    //    })
    //    .catch((error) => {
    //      console.log("error",error); 
    //    })

       reset();
     
    }


    return (
        <>
            <Navbar />
            <SubHeader title="HOTEL" image={require("assets/img/hotel/danangmarriottbeach.jpeg")} />
            <div className="wrapper position-relative">
                <div className="main my-5">
                    <section className="section gb nopadtop">
                        <div className="container">
                            <div className="boxed boxedp4">
                                <div className="row">
                                    <div className="col-md-12 section-title">
                                        <h2 className="text-center font-weight-bold">HOTEL</h2>
                                    </div>
                                </div>

                                <div className="col-md-12 mb-5">
                                    <div className="bg-light my-3">
                                        <section className="position-relative">
                                            <div className="container position-relative py-9">
                                                <div className="row pt-5 pt-lg-11 align-items-end">
                                                    <div className="col-12 col-xl-9 mx-auto text-justify">
                                                        <p className="fs-5 w-lg-60 mb-0 mx-auto ">
                                                        The FIBS Global Conference 2025 will be held at <span className='font-weight-bold'>Danang Marriott Resort & Spa. </span>   
                                                        We have worked out a special package with the Marriott Group to offer guests accommodations.

                                                        </p>
                                                         <p className="fs-5 w-lg-60 mb-0 mx-auto mt-2">
                                                    Nestled along the pristine shores of Vietnam, the Marriott Danang is a true oasis of luxury and serenity. As Marriott's first beach resort in Vietnam, it embodies the brand's commitment to exceptional hospitality by offering unparalleled experiences and world-class amenities and conference facilities. With its breathtaking coastal views, exquisite dining options, and myriad recreational activities, Marriott Danang sets a new standard for seaside luxury in this enchanting destination.
                                                     
                                                       </p>
                                                

                                                        <p className='my-4'>
                                                            {/* We have worked out with both hotels to offer to our delegates preferential rates. */}
                                                        </p>

                                                        <TableContainer component={Paper} >
                                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Danang Marriott Resort & Spa</TableCell>
                                                                        <TableCell>Deluxe Room <span className='' style={{fontSize: '12px'}}>(inclusive of breakfast)</span></TableCell>
                                                                        <TableCell align="right">4,200,000 VND / night</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                {/* <TableBody>
                                                                    <TableRow >
                                                                        <TableCell>Le Meridien Mina Seyahi</TableCell>
                                                                        <TableCell>Deluxe Standard</TableCell>
                                                                        <TableCell align="right">AED 1‘250 / night</TableCell>
                                                                    </TableRow>
                                                                </TableBody> */}
                                                            </Table>
                                                        </TableContainer>
                                                        <p><small>Charges are subject to change without prior notice</small></p>

                                                        <p className='fs-5 w-lg-60 mb-0 mx-auto mt-3'><span className='font-weight-bold'>Address:</span> No 7 Trường Sa, Street, Ngũ Hành Sơn, Đà Nẵng 550000, Vietnam</p>
                                                        {/* <p className='fs-5 w-lg-60 mb-0 mx-auto '><span className='font-weight-bold'>Rates Include: </span>
                                                            <li>Breakfast </li>
                                                            <li>Free WiFi Access</li>
                                                        </p> */}

                                                        
                                                    </div>

                                                </div>
                                            </div>
                                        </section>

                                        <section className="position-relative overflow-hidden pt-5 pb-3">
                                            <div className="container-fluid position-relative">
                                                <div className="row align-items-sm-center align-items-md-start">
                                                    <div className="col-6 col-sm-3 mb-4 mb-sm-0">

                                                        <img
                                                            className="img-fluid rounded-4"
                                                            alt="..."
                                                            src={require("assets/img/hotel/Deluxe King Garden.jpg")}
                                                        ></img>

                                                        <img
                                                            className="d-lg-none img-fluid rounded-4 mt-3"
                                                            alt="..."
                                                            src={require("assets/img/hotel/Deluxe Twin Ocean view.jpg")}
                                                        ></img>

                                                    </div>
                                                    <div className="col-6 col-sm-3 mb-4 mb-sm-0">
                                                        <img src={require("assets/img/hotel/Danang Marriott Resort & Spa villa.jpg")} className="img-fluid rounded-4 mb-3" alt="" />
                                                        {/* <img src={require("assets/img/hotel/Danang Marriott Resort & Spa -POOL.jpg")} className="img-fluid rounded-4" alt="Executive_Suite.jpg" /> */}
                                                    </div>
                                                    <div className="col-sm-3 col-5">
                                                        <img src={require("assets/img/hotel/MADAME SON.jpg")} className="img-fluid rounded-4" alt="" />
                                                    </div>
                                                    <div className="col-sm-3 col-7">
                                                        <img src={require("assets/img/hotel/QUANSPA.jpg")} className="img-fluid rounded-4" alt="Presidential Suite" />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <div className="col-12" id="pending" align="center">
                                               <a href='https://www.marriott.com/event-reservations/reservation-link.mi?id=1720783473281&key=GRP&app=resvlink' target='_blank' type='button' className='btn btn-danger'> Book Now</a>
                                        </div>


                                       
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section >
                </div >
                <PanelCountdown date="02/18/2025" />
                {/* <DarkFooter /> */}
            </div >
        </>
    );
}

export default Hotel;
